<template>
  <CFooter :fixed="false">
    <div>
      <a href="https://www.cordiance.com" target="_blank">Pulse</a>
      <span class="ml-1">&copy; {{ new Date().getFullYear() }} Cordiance.</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a href="https://www.cordiance.com">MODIOS</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>
