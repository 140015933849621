<template>
  <div
    class="c-app"
    :class="{
      'c-dark-theme': darkMode,
    }"
  >
    <CWrapper>
      <CHeader>
        <CHeaderBrand
          style="
            margin-left: 1rem;
            margin-right: 2rem;
            margin-top: auto;
            margin-bottom: auto;
          "
          to="/"
        >
          <img
            name="logo"
            height="40"
            :alt="$t('menu.pulseNav')"
            :src="$brand('modios-pulse-nav.png', '/img')"
          />
        </CHeaderBrand>
      </CHeader>
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid>
            <CJumbotron>
              <h1 class="display-3">Logged Out</h1>
              <p class="lead">You are now logged out of Pulse</p>
              <p>Click below to log back into the application</p>
              <CButton color="primary" :to="{ name: 'Dashboard' }" size="lg"
                >Login</CButton
              >
            </CJumbotron>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </CWrapper>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TheFooter from '@/containers/TheFooter';
export default {
  name: 'LoggedOut',
  components: {
    TheFooter,
  },
  computed: {
    ...mapGetters('coreui', ['darkMode']),
  },
};
</script>
